import revive_payload_client_QcERh0wiNE from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_bufferutil@4.0.8_ioredis@5.4.1_magicast@_ukw72huljziyf5dzwvinxbi2ey/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XDly1Kce8H from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_bufferutil@4.0.8_ioredis@5.4.1_magicast@_ukw72huljziyf5dzwvinxbi2ey/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2vIKlc96Kn from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_bufferutil@4.0.8_ioredis@5.4.1_magicast@_ukw72huljziyf5dzwvinxbi2ey/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_lis0fPjXkG from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_bufferutil@4.0.8_ioredis@5.4.1_magicast@_ukw72huljziyf5dzwvinxbi2ey/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qBELmv0l0y from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_bufferutil@4.0.8_ioredis@5.4.1_magicast@_ukw72huljziyf5dzwvinxbi2ey/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_q3xwftqMDO from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_bufferutil@4.0.8_ioredis@5.4.1_magicast@_ukw72huljziyf5dzwvinxbi2ey/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_QW67UWUfIW from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_bufferutil@4.0.8_ioredis@5.4.1_magicast@_ukw72huljziyf5dzwvinxbi2ey/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_OE5cGyhxRM from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_bufferutil@4.0.8_ioredis@5.4.1_magicast@_ukw72huljziyf5dzwvinxbi2ey/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_o3lRgDRY2a from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.26.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+api@1._3qnhnyqstsvrwjnksu6yvlk5ii/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
export default [
  revive_payload_client_QcERh0wiNE,
  unhead_XDly1Kce8H,
  router_2vIKlc96Kn,
  payload_client_lis0fPjXkG,
  navigation_repaint_client_qBELmv0l0y,
  check_outdated_build_client_q3xwftqMDO,
  chunk_reload_client_QW67UWUfIW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OE5cGyhxRM,
  sentry_client_o3lRgDRY2a,
  sentry_client_config_o34nk2sJbg,
  analytics_client_mtqVw3YRXk
]