import * as Sentry from "@sentry/nuxt";
Sentry.init({
    dsn: "https://66c8c15ae91bb0cbe4dc8b7a036368db@o4507794867814400.ingest.de.sentry.io/4507794875940944",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.replayIntegration()
    ]
});
